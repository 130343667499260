<template>
  <v-container class="px-lg-10">
    <v-container class="px-lg-10">
      <v-data-table
        :headers="headers"
        :items="wallets"
        :search="search"
        :items-per-page="25"
        class="elevation-3"
        mobile-breakpoint="100"
        calculate-widths
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-8 py-8"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-8 py-8 text-right"
            >
              <v-btn
                color="orange"
                dark
                class="mb-2"
                @click="cancelButtonClick"
              >
                KEMBALI
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.nominal`]="{ item }">
          <div :style="{color:getColor(item.nominal)}">
            Rp. {{ item.nominal || 0 | dotThousandSeparator }}
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
        </template>

        <template
          v-slot:[`item.actions`]="{ item }"
        >
          <v-btn
            small
            dark
            color="purple"
            class="mr-2"
            @click="detailButtonClick(item)"
          >
            DETAIL
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      headers: [
        { text: 'Nama Transaksi', align: 'start', value: 'type', sortable: false },
        { text: 'Keterangan', value: 'description', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Tanggal', value: 'tanggal', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      wallets: [],
      page: 1,
      pageCount: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id, 1, this.itemsPerPage)
    },

    methods: {
      initialize (id, page, limit) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('PARTNER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          w_hash: id,
          page: page,
          limit: limit,
        }

        axios.post('/t/wallet/trx', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.wallets = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(this.$route.params.id, e, this.itemsPerPage)
      },

      getColor (id) {
        let retVal = ''
        id > 0 ? retVal = 'green' : retVal = 'red'
        return retVal
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Wallet' })
      },

      detailButtonClick (item) {

      },
    },
  }
</script>
